<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
   


    <!-- <v-layout wrap> -->
    <v-layout wrap>
        <v-flex xs12 pt-4>

   
        <v-card elevation="0">
          <v-form v-model="addgrade" ref="addgrade">
             <v-layout>
                <v-flex xs6 text-left>
                  <span class="headline">Banner Image</span>
                </v-flex>
                <v-flex xs6 text-right>
                  <v-btn
                    color="green"
                    outlined
                    small
                    @click="$router.push('/EventsPage')"
                    >Event List
                    <v-icon small>mdi-arrow-left</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
                <v-layout justify-center wrap style="border-bottom:1px dashed">
                  <!-----------------upload banner image------------------>
                  <v-flex xs10 >
                    <v-layout wrap pt-1 align-center>
                      <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                        <v-card outlined>
                          <v-icon
                            size="25px"
                            id="pro_pic3"
                            @click="$refs.files3.click()"
                            >mdi-plus</v-icon
                          >
                          <span
                            style="
                              color: #828282;
                              font-family: montserratLight;
                              font-size: 14px;
                            "
                            >Upload Banner Image</span
                          >
                          <input
                            v-show="false"
                            accept="image/*"
                            id="file3"
                            ref="files3"
                            multiple="multiple"
                            type="file"
                            @change="uploadImages2"
                          />
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs2 text-left>
                     <v-btn 
                color="blue darken-1"
                text
                :disabled="!addgrade"
                @click="uploadAllImages2()"
              >
                Save
              </v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    v-for="(item, i) in bannerimageArray"
                    :key="i"
                    pa-1
                  >
                    <v-img :src="item" height="50px" contain>
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImageArray(i)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                </v-layout>
                 <v-layout wrap justify-center>
              <v-flex xs12 v-if="bannerList">
                <v-layout wrap v-if="bannerList.length>0" justify-center>
                    <v-flex xs3 pa-2 v-for="(item, i) in bannerList" :key="i" align-self-center>
                        <v-card tile>
                          <v-img  contain
                       :src="mediaURLnews + item"
                    /></v-card>
                     <v-flex xs12>
                      <v-btn small outlined @click="removeImageArray1(i)">Delete</v-btn>
                    </v-flex>
                    </v-flex>
                     
                </v-layout>
                   <v-layout wrap v-else>
                       <v-flex xs2 align-self-center>No images found</v-flex>
                </v-layout>
              </v-flex>
                 </v-layout>
              <v-spacer></v-spacer>
            
             
          </v-form>
        </v-card>
             </v-flex>
    </v-layout>
     
      <v-dialog
        v-model="cropBannerImageDialog"
        persistent
        :width="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? '100vw'
            : $vuetify.breakpoint.name == 'md'
            ? '80vw'
            : $vuetify.breakpoint.name == 'lg'
            ? '50vw'
            : '40vw'
        "
      >
        <v-card>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12>
              <ImageCropper
                :image="currentBannerImage"
                :key="currentBannerImage"
                @stepper="imageCropper2"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
        
        <v-dialog v-model="deletedialogue" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          pa-0
                          py-0
                          px-0
                          v-on="on"
                          v-bind="attrs"
                          color="#FFFFFF"
                          style="font-size: 15px"
                          >mdi-trash-can-outline</v-icon
                        >
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Photo?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deletedialogue = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="removeImageArray(pageId, index-1)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                      
    <!-- </v-layout> -->
  </div>
</template>


<script>
import axios from "axios";
import ImageCropper from "./../Common/imageCropper";

// import store from "./../../store";
export default {
  components: {
    ImageCropper,
  },
  data() {
    return {
      msg: "",
      dialog: false,
      addgrade: false,
      documentFile1: [],
      timeout: 5000,
      count: "",
      snackbar: false,
      appLoading: false,
      showSnackBar: false,
      todate: null,
      menu2: false,
         page: 1,
      Pagelength: 0,
      deletedialogue:false,
      dialoge:false,
      //upload image
      imageArray: [],
      bannerimageArray: [],
      galleryimageArray: [],
      Images1: new FormData(),
      formData: new FormData(),
      Images3: new FormData(),
       cropImageDialog: false,
      cropBannerImageDialog: false,
      cropGalleryImageDialog: false,
      currentImage: null,
      currentBannerImage: null,
      currentGalleryImage: null,
      //upload docs
      docsdata: new FormData(),
      selectedFiles: null,
      selectedFiles2: null,
      selectedFiles3: null,
      image: null,
      contactAddress: null,
     curId:null,
     bannerList:[],
      id1: this.$route.query.id,

    };
  },
 mounted() {
    this.getData();
  },
  methods: {
       removeImageArray1(index) {
          var data = {};
          data["position"] = index;
          data["id"] = this.id1;

          axios({
            method: "post",
            url: "/event/removebannerImage",
            data: data,
            headers: {
              "token": localStorage.getItem("token"),
            },
          }).then((response) => {
            if (response.data.status) {
             this.getData();
            }
          });
    },
       getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/media/admin/event/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: this.page,
          limit: 15,
          id:this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.bannerList = response.data.data.bannerImage;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;

          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  
    //banner image
    imageCropper2(payload) {
      this.cropBannerImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages2(payload.image);
      }
    },
    uploadImages2(event) {
      console.log("upload image2");
      if (this.bannerimageArray.length < 10) {
        this.currentBannerImage = URL.createObjectURL(event.target.files[0]);
        this.cropBannerImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages2(image) {
      this.selectedFiles2 = image;
      this.formData.append("bannerImage", this.selectedFiles2);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.bannerimageArray.push(urls);
        if (this.bannerimageArray) {
          this.image = this.bannerimageArray[0];
        }
      };
      console.log("image2=", this.selectedFiles2);
    },
    
    //upload image2
    uploadAllImages2() {
      this.appLoading = true;
      this.formData.append("id", this.$route.query.id);
      axios({
        method: "POST",
        url: "/media/upload/event/images/banner",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formData = null;
            this.bannerimageArray = [];
            // window.location.reload();
          this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
            // this.$router.push("/Seller/Product/Added/" + item);
          } else {
            this.formData = new FormData();
            this.bannerimageArray = [];
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
//
  
  },
};
</script>







